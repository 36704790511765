
/* Contact widget float */

.contact_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #BCF0DA;
  color: #03543F;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.contact-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .contact-icon {
      margin-top: 10px;
  }

  .contact_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

/* whatsapp for desktop */
.whatsapp_float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 50px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 25%;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}




/* call button */
.call_float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 70px;
  right: 50px;
  background-color: blue;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.call-icon {
  margin-top: 25%;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .call-icon {
      margin-top: 10px;
  }

  .call_float {
      width: 40px;
      height: 40px;
      bottom: 70px;
      right: 10px;
      font-size: 22px;
  }
}

/* message */
.message_float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 120px;
  right: 50px;
  background-color: yellow;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.message-icon {
  margin-top: 50%;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .message-icon {
      margin-top: 10px;
  }

  .message_float {
      width: 40px;
      height: 40px;
      bottom: 120px;
      right: 10px;
      font-size: 20px;
  }
}
